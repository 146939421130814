import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlUploadModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { MOBILE_REGEXP } from '@common-src/model/regexp';

export class CorpEntityModel extends BaseEntityModel {
    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '企业代码',
        type: FormControlType.TEXT,
        required: true,
        readonly: true
    } as FormControlTextModel)
    code: string = undefined;

    @FormControl({
        label: '创建人账号',
        type: FormControlType.TEXT,
        required: true,
        updateHidden: true
    } as FormControlTextModel)
    adminUsername: string = 'admin';

    @FormControl({
        label: '创建人手机号',
        type: FormControlType.TEXT,
        pattern: MOBILE_REGEXP,
        required: true,
        updateHidden: true
    } as FormControlTextModel)
    phone: string = undefined;

    @FormControl({
        label: '创建人密码',
        type: FormControlType.TEXT,
        min: 6,
        max: 16,
        // pattern: NUMBER_LETTER_UNDERLINE_REGEXP,
        required: true,
        placeholder: '请输入6到16个字符',
        updateHidden: true
    } as FormControlTextModel)
    password: string = undefined;

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    remark: string = undefined;

    adminUserPhone: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '管理员',
                dataIndex: 'adminUsername'
            },
            {
                title: '管理员手机号',
                dataIndex: 'adminUserPhone'
            },
            {
                title: '描述',
                dataIndex: 'remark'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class CorpDetailEntityModel extends BaseEntityModel {
    @FormControl({
        label: '企业名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    dispName: string = undefined;

    @FormControl({
        label: '系统LOGO',
        type: FormControlType.UPLOAD,
        listType: 'picture-card',
        showUploadList: true,
        maxFileNumber: 1
    } as FormControlUploadModel)
    logoUrl: Array<any> = undefined;
    @FormControl({
        label: '登陆页背景',
        type: FormControlType.UPLOAD,
        listType: 'picture-card',
        accept: '.jpg,.png',
        showUploadList: true,
        message: '登录页面背景图，建议上传尺寸1920*1080',
        maxFileNumber: 1
    } as FormControlUploadModel)
    backImgUrl: Array<any> = undefined;

    toModel(json): any {
        super.toModel(json);
        const jsonLogoUrl = _.get(json, 'logoUrl');
        const jsonBackImgUrl = _.get(json, 'backImgUrl');
        if (jsonLogoUrl) {
            if (Array.isArray(jsonLogoUrl)) {
                this.logoUrl = jsonLogoUrl;
            } else {
                this.logoUrl = [{ url: jsonLogoUrl, type: 'image' }];
            }
        } else {
            this.logoUrl = [];
        }
        if (jsonBackImgUrl) {
            if (Array.isArray(jsonBackImgUrl)) {
                this.backImgUrl = jsonBackImgUrl;
            } else {
                this.backImgUrl = [{ url: jsonBackImgUrl, type: 'image' }];
            }
        } else {
            this.backImgUrl = [];
        }
        return this;
    }

    toService() {
        const data: any = super.toService();
        if (this.logoUrl) {
            data.logoUrl = _.get(this.logoUrl, '[0].url');
        } else {
            data.logoUrl = '';
        }
        if (this.backImgUrl) {
            data.backImgUrl = _.get(this.backImgUrl, '[0].url');
        } else {
            data.backImgUrl = '';
        }
        return data;
    }
}

export class CorpQueryModel extends QueryPageModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT
    })
    name: string = undefined;

    toService() {
        // const data: any = super.toService();
        return {
            params: this.name
        };
    }
}
